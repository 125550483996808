<footer>
  <div class="bg-primary pt-6">
    <div class="container-xl text-white">
      <div class="row">
        <div *ngIf="!isMobile" class="col d-flex justify-content-center justify-content-md-start">
          <ul class="list-inline mb-2">
            <li class="list-inline-item mr-4">
              <a href="https://itunes.apple.com/us/app/labcorp-patient/id1361264072" target="_blank"
                ><img alt="Apple App Store" class="img-fluid h-42" src="assets/images/apple-app-store.svg"
              /></a>
            </li>
            <li class="list-inline-item">
              <a href="https://play.google.com/store/apps/details?id=com.labcorp.patientportal&hl=en_US" target="_blank"
                ><img alt="Google Play" class="img-fluid h-42" src="assets/images/google-play.svg"
              /></a>
            </li>
          </ul>
        </div>
        <div class="col d-flex justify-content-center justify-content-md-end">
          <ul class="list-inline mb-2">
            <li class="list-inline-item mr-4">
              <ui-button [buttonText]="'Back to Top'" [rank]="'light'" [iconLeft]="'arrow_upward'" (handleClick)="goToTop()"></ui-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-navy py-4 mt-4">
      <div class="container-xl text-white">
        <small>
          Labcorp | {{ appName }}™ © {{ appYear }} Laboratory Corporation of America® Holdings. All Rights Reserved. Visit
          <a href="https://www.labcorp.com/" target="{{ browserTarget }}" class="text-white">labcorp.com</a>
          |
          <a href="https://www.labcorp.com/hipaa-privacy" target="{{ browserTarget }}" class="text-white">Privacy Statement</a>
          |
          <a class="ot-sdk-show-settings text-white" style="cursor: pointer">Do Not Sell or Share My Personal Information</a>
        </small>
      </div>
    </div>
  </div>
</footer>
