import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSelfOrders from './self-orders.reducer';

export const selfOrdersStateSelector = createFeatureSelector<fromSelfOrders.SelfOrdersState>(
  fromSelfOrders.selfOrdersFeatureKey
);

export const selectSelfOrdersPayloadStatus = createSelector(
  selfOrdersStateSelector,
  (state: fromSelfOrders.SelfOrdersState) =>
    state.loadSelfOrdersPayloadRequestStatus
);

export const selectSelfOrders = createSelector(
  selfOrdersStateSelector,
  (state: fromSelfOrders.SelfOrdersState) => state.selfOrders
);

export const selectOrderPdfRequestStatus = createSelector(
  selfOrdersStateSelector,
  (state: fromSelfOrders.SelfOrdersState) => state.getOrderPdfRequestStatus
);
export const selectOrderPdf = createSelector(
  selfOrdersStateSelector,
  (state: fromSelfOrders.SelfOrdersState) => state.orderPdf
);
export const selectPdfList = createSelector(
  selfOrdersStateSelector,
  (state: fromSelfOrders.SelfOrdersState) => state.pdfList
);

export const dashboardSelfOrdersQuery = {
  selfOrdersStateSelector,
  selectSelfOrdersPayloadStatus,
  selectSelfOrders,
  selectOrderPdfRequestStatus,
  selectOrderPdf,
  selectPdfList,
};
