<div class="row align-items-baseline">
  <ng-container *ngIf="today.equals(control.value) || tomorrow.equals(control.value); then thenTemplate; else elseTemplate"></ng-container>
  <ng-template #thenTemplate>
    <div class="col text-center">
      <button
        type="button"
        [class]="'btn btn-lg ' + (today.equals(control.value) ? 'text-black curved-underline-secondary' : 'text-muted')"
        (click)="control.setValue(today)"
      >
        Today
      </button>
      <button
        type="button"
        [class]="'btn btn-lg ' + (tomorrow.equals(control.value) ? 'text-black curved-underline-secondary' : 'text-muted')"
        (click)="control.setValue(tomorrow)"
      >
        Tomorrow
      </button>
    </div>
  </ng-template>
  <ng-template #elseTemplate>
    <div class="col text-right">
      <button type="button" class="btn btn-link" (click)="control.setValue(today)">Return to today</button>
      <button type="button" class="btn btn-lg text-black curved-underline-secondary" (click)="d.toggle()">
        {{ format(control.value) }}
      </button>
    </div>
  </ng-template>

  <div class="col col-md-auto">
    <input
      type="hidden"
      [id]="domId"
      [name]="domId"
      [formControl]="control"
      ngbDatepicker
      navigation="arrows"
      [minDate]="today"
      [positionTarget]="buttonEl"
      #d="ngbDatepicker"
    />
    <!-- TODO: Should be "calendar_today" but requires Material Icons update -->
    <button class="btn btn-lg text-muted" type="button" (click)="d.toggle()" #buttonEl>
      <i class="material-icons">today</i>
      Select Date
    </button>
  </div>
</div>
