import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AccountVerificationState,
  accountVerificationFeatureKey,
} from './account-verification.reducer';

export const accountVerificationStateSelector = createFeatureSelector<AccountVerificationState>(
  accountVerificationFeatureKey
);

export const selectLnivQuestions = createSelector(
  accountVerificationStateSelector,
  (state: AccountVerificationState) => state.lexisNexisQuiz
);

export const selectLnivQuestionsRequestStatus = createSelector(
  accountVerificationStateSelector,
  (state: AccountVerificationState) => state.getLexisNexisQuizRequestStatus
);

export const selectLnivQuestionsErrorResponse = createSelector(
  accountVerificationStateSelector,
  (state: AccountVerificationState) => state.quizErrorResponse
);

export const selectLnivGradeQuizResult = createSelector(
  accountVerificationStateSelector,
  (state: AccountVerificationState) => state.gradedQuizResponse
);

export const selectLnivGradeQuizResultRequestStatus = createSelector(
  accountVerificationStateSelector,
  (state: AccountVerificationState) => state.getGradedQuizResultRequestStatus
);

export const selectLnivGradeQuizErrorResponse = createSelector(
  accountVerificationStateSelector,
  (state: AccountVerificationState) => state.gradedQuizErrorResponse
);

export const accountVerificationQuery = {
  accountVerificationStateSelector,
  selectLnivQuestions,
  selectLnivQuestionsRequestStatus,
  selectLnivGradeQuizResult,
  selectLnivGradeQuizResultRequestStatus,
  selectLnivQuestionsErrorResponse,
  selectLnivGradeQuizErrorResponse,
};
