import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  SmartyStreetsState,
  smartyStreetsFeatureKey,
} from './smartystreets.reducer';

export const smartyStreetsStateSelector = createFeatureSelector<SmartyStreetsState>(
  smartyStreetsFeatureKey
);

export const selectSmartyStreetsInitializeSuccess = createSelector(
  smartyStreetsStateSelector,
  (state) => state.initializeSuccess
);

export const selectSmartyStreetsSuggestResults = createSelector(
  smartyStreetsStateSelector,
  (state) => state.suggestResults
);
