import * as moment from 'moment';

import { genders } from './genders';
import { IEmailAddress } from './IEmailAddress.interface';
import { IPatient } from './IPatient.interface';
import { IPhone } from './IPhone.interface';
import { INotificationPreference } from './notificationPreference';
import { IAddress } from '..';

export class Patient implements IPatient {
  id?: number;
  loginEmail?: string;
  lpid?: number;
  firstName!: string;
  middleName?: string;
  lastName!: string;
  dateOfBirth!: string;
  addresses!: IAddress[];
  phones!: IPhone[];
  emailAddresses!: IEmailAddress[];
  hashId?: string;
  gender?: string;
  ethnicity?: string;
  race?: string;
  notificationPreferences?: INotificationPreference[];
  dependents?: IPatient[];
  dependent?: IPatient;
  relationshipType?: string;
  usePrimaryAddressOfPrimaryPatient?: boolean;
  usePrimaryPhoneOfPrimaryPatient?: boolean;
  usePrimaryEmailOfPrimaryPatient?: boolean;
  authenticationQuizStatus!: string;
  hasFailedMaxVerificationAttempts!: boolean;
  clinicalTrialsPreferenceStatus?: string;
  registrationVerificationType?: string;
  profileVerificationDate?: string;
  isDependent!: boolean;
  emailVerified?: boolean;

  get authenticationQuizPassed(): boolean {
    return this.authenticationQuizStatus === 'passed';
  }

  get primaryAddress(): IAddress {
    return this.addresses.length > 1
      ? this.addresses.filter((address) => address.isPrimary)[0]
      : this.addresses[0];
  }

  get orderedAddresses(): IAddress[] {
    // Primary address comes first
    let addresses: IAddress[] = [];
    if (this.addresses && this.primaryAddress) {
      addresses.push(this.primaryAddress);
    }
    addresses = addresses.concat(
      this.addresses.filter((address) => !address.isPrimary)
    );
    return addresses;
  }

  get primaryEmailAddress(): IEmailAddress {
    return this.emailAddresses.length > 1
      ? this.emailAddresses.filter((emailAddress) => emailAddress.isPrimary)[0]
      : this.emailAddresses[0];
  }

  get orderedEmailAddresses(): IEmailAddress[] {
    // Primary email address comes first
    let emailAddresses: IEmailAddress[] = [];

    if (this.emailAddresses && this.primaryEmailAddress) {
      emailAddresses.push(this.primaryEmailAddress);
    }
    emailAddresses = emailAddresses.concat(
      this.emailAddresses.filter((emailAddress) => !emailAddress.isPrimary)
    );
    return emailAddresses;
  }

  get primaryPhone(): IPhone {
    return this.phones.length > 1
      ? this.phones.filter((phone) => phone.isPrimary)[0]
      : this.phones[0];
  }

  get orderedPhones(): IPhone[] {
    // Primary phone comes first
    let phones: IPhone[] = [];
    if (this.phones && this.primaryPhone) {
      phones.push(this.primaryPhone);
    }
    phones = phones.concat(this.phones.filter((phone) => !phone.isPrimary));
    return phones;
  }

  get avatarUrl(): string {
    let designation: string;
    const gender = this.gender ? this.gender.toLowerCase() : undefined;

    if (gender !== 'unknown') {
      if (this.isMinor) {
        designation = gender === 'male' ? 'boy' : 'girl';
      } else {
        designation = gender === 'male' ? 'man' : 'woman';
      }
    } else {
      designation = 'unknown';
    }

    return `assets/portal/svg/${designation}-avatar.svg`;
  }

  get fullName(): string {
    const mi = this.middleName ? this.middleName : '';
    return `${this.firstName} ${mi} ${this.lastName}`;
  }

  get genderDisplay(): string {
    if (this.gender === genders[genders.length - 1].value) {
      return `I'd rather not say`;
    }

    return this.gender ? this.gender : `I'd rather not say`;
  }

  get isDemographicsUpdateDue(): boolean {
    return (
      moment.duration(moment().diff(this.profileVerificationDate)).asDays() >=
      90
    );
  }

  get formattedDob(): string {
    return moment(this.dateOfBirth).format('MM/DD/YYYY');
  }

  get isMinor(): boolean {
    const momentDob = moment(this.dateOfBirth).toDate();
    return moment().diff(momentDob, 'years', false) < 18;
  }
}
